import React from 'react'
import "./css/userSearch.css"
import { Container, Form, FormGroup, Label, Input, Button, Modal, ModalBody, Row, Col } from 'reactstrap';
import phoneCodes from "./phoneCodes"

const UserCard = ({user, openModal, setPath, setRedirect}) => {
    return (
        <div key={user.id} className="userCard">
          <div className="row text-center">
            <div className="col-md-4">
              <p>{user.firstName} {user.lastName}</p>
              <p>Email: {user.email}</p>
              <p>Phone Number: {user.phoneNumber}</p>
              <p>Phone Code: {user.phoneCode}</p>
              {/* <p>Country: {phoneCodes.filter(p => p.dial_code == user.phoneCode)}</p> */}
            </div>
            <div className="col-md-4">
              <Button
                color="success"
                onClick={() => {
                  setPath(`/user/${user.id}`);
                  setRedirect(true);
                }}
              >
                View More
              </Button>
              <Button color='info' className="ml-2" onClick={() => {
                setPath(`/users/reset/${user.id}`)
                setRedirect(true)
              }}>Reset Password</Button>
            </div>
            <div className="col-md-4">
              <Button color="primary" className="mr-2" onClick={() => {
                setPath(`/users/edit/${user.id}`);
                setRedirect(true)
              }}>
                Edit
              </Button>
              <Button color="danger" onClick={() => openModal(user.id)}>Delete</Button>
            </div>
          </div>
        </div>
    )
}

export default UserCard
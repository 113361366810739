import React, { Component, useState } from 'react';
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from 'reactstrap';
import Logo from '../images/logo.png';
import { Link } from 'react-router-dom';




const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false)
  

  const toggle = () => {
    try{
      setIsOpen(!isOpen)
    }catch(err) {
      console.log(err)
    }
  }
  
  
  
  return (
    <div>
      <Navbar className="navbar navbar-dark bg-primary" expand="md">
        <NavbarBrand href={Logo}>Yesh Admin</NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <Link to="/users" className="navigationLink">
                Users
              </Link>
            </NavItem>
            <NavItem>
              <Link to="/notifications" className="navigationLink ml-3">
                Notifications
              </Link>
            </NavItem>
            <NavItem>
              <Link to="/listing" className="navigationLink ml-3">
                Listing
              </Link>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}


export default Navigation

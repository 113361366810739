import React, { Component, useState, useEffect } from 'react';
import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import Navigation from './Navigation';
import Loading from '../components/loading';
import UserDisplay from '../components/UserDisplay';

const USER_DATA_QUERY = gql`
  query adminGetUser($userId: String!) {
    adminGetUser(userId: $userId) {
      id
      firstName
      lastName
      email
      phoneNumber
      governmentId
      gender
      profilePicture
      description
      displayLocation
      languages
      passportNumber
      dateOfBirth
      marriageStatus
      verified
      emergencyContact {
        firstName
        lastName
        email
        phoneNumber
        language
      }
    }
  }
`;

const GET_USER_LISTINGS_QUERY = gql`
  query adminGetUserListings($userId: String!) {
    adminGetUserListings(userId: $userId) {
      id
      type
      guest
      rooms
      beds
      essentials
      wifi
      shower
      cooking
      TV
      coffee
      kitchen
      privateLounge
      landryWashing
      landryDryer
      pool
      hotTub
      images
      place
      space
      title
      children
      infants
      smoking
      alcohol
      additionalRules
      haveChildren
      havePets
      noParking
      sharedSpace
      checkInFrom
      checkInTo
      minNights
      maxNights
      location {
        apartmentSuite
        streetAddress
        city
        state
        country
        postCode
        longitude
        latitude
      }
      completedSections
      sleepingArrangement {
        single
        double
        queen
        sofaBed
        floorMattress
        airMattress
        toddlerBed
      }
      caravanSpace {
        powered
        width
        height
      }
      tentSpace {
        width
        height
      }
      published
      price
      currency
      preferedGender
      preferedMaritial
      stays
    }
  }
`;

const ADMIN_GET_HOST = gql`
  query adminGetHost($userId: String!) {
    adminGetHost(userId: $userId) {
      id
      hostVerified
    }
  }
`;

const SingleUser = (props) => {
  const id = props.match.params.id;
  const [userDataQuery, user] = useLazyQuery(USER_DATA_QUERY, {
    variables: { userId: id },
  });
  const [userListingsQuery, listings] = useLazyQuery(GET_USER_LISTINGS_QUERY, {
    variables: { userId: id },
  });
  const [hostQuery, host] = useLazyQuery(ADMIN_GET_HOST, {
    variables: { userId: id },
  });

  let userDisplay;

  const getData = async () => {
    try {
      await userDataQuery();
      await userListingsQuery();
      await hostQuery();
      console.log('Got here');
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (user.loading || listings.loading) return <Loading />;

  if (user.data && listings.data && host.data) {
    userDisplay = (
      <UserDisplay
        user={user.data.adminGetUser}
        listings={listings.data.adminGetUserListings}
        host={host.data.adminGetHost}
      />
    );
  }

  return (
    <div>
      <Navigation />
      {userDisplay}
    </div>
  );
};

export default SingleUser;

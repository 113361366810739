import React, { useState } from 'react'
import Navigation from './Navigation'
import EditUserContent from '../components/editUserContent'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'
import Loading from '../components/loading'


const EditUser = (props) => {
  // Get the information about the user  
  const {loading, error, data} = useQuery(GET_USER, {variables: {userId: props.match.params.id}})
  const [editUser, {loading: editUserLoading, error: editUserError}] = useMutation(EDIT_USER)
  
  if(loading ) {
    return <Loading />
  } 

  if(editUserError) {
    console.log(editUserError.message)
  }
  return (
    <div>
      <Navigation />
      <EditUserContent userData={data.adminGetUser} editUser={editUser} error={error} />
    </div>
  )
}


const GET_USER = gql`
  query adminGetUser($userId: String!) {
    adminGetUser(userId: $userId) {
      id
      firstName
      lastName
      gender
      marriageStatus
      email
      dateOfBirth
      passportNumber
      phoneCode
      phoneNumber
      emergencyContact {
        firstName
        lastName
        email
      }
      description
      languages
      displayLocation
      verified
      profilePicture
      governmentId
    }
  }
`

const EDIT_USER = gql`
  mutation adminEditUser($userId: String!, $firstName: String!, $lastName: String!, $email: String!, $phoneNumber: String!, $phoneCode: String!, $emergencyContact: emergencyContactInput!, $gender: String!, $description: String!, $languages: [String!], $displayLocation: String!, $dateOfBirth: String!, $passportNumber: String!, $marriageStatus: String!) {
    adminEditUser(userId: $userId, firstName: $firstName, lastName: $lastName, email: $email, phoneNumber: $phoneNumber, phoneCode: $phoneCode, emergencyContact: $emergencyContact, gender: $gender, description: $description, languages: $languages, displayLocation: $displayLocation, dateOfBirth: $dateOfBirth, passportNumber: $passportNumber, marriageStatus: $marriageStatus) {
      id
    }
  }
`

export default EditUser;
import React, {useState, useEffect} from "react"
import Navigation from "./Navigation"
import {Container, Row, Col, Form, Label, Input, FormGroup, Button} from "reactstrap"
import { useLazyQuery } from "@apollo/react-hooks"
import { gql } from 'apollo-boost';
import ListingCard from "../components/listingCard"
import { Redirect } from 'react-router-dom';

const ListingSearch = () => {
    const [search, setSearch] = useState("")
    const [listingSearchQuery, listingSearchData] = useLazyQuery(ADMIN_SEARCH_LISTING, {variables: {search}})
    const [redirect, setRedirect] = useState(false)
    const [path, setPath] = useState("")
    
    const onSubmit = async(e) => {
        try {
            e.preventDefault()
            listingSearchQuery()
        }catch(err) {
            console.log(err)
        }
    }



    if (redirect) {
        return <Redirect to={path} />;
    }
    return (
        <div className="text-center">
            <Navigation />
            <Container>
                <h1>Listing Search</h1>
                <Form onSubmit={onSubmit}>
                    <FormGroup>
                        <Label for="search">Search:</Label>
                        <Input
                            type="text"
                            id="search"
                            name="search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Search by location or title"
                        />
                    </FormGroup>
                    <Button color="primary">Search</Button>
                </Form>
                <Container className="mt-5 mb-5">
                    <Row className="text-center">
                        <Col md="3">
                            Listing Name
                        </Col>
                        <Col md="3">
                            View
                        </Col>
                        <Col md="3">
                            Edit
                        </Col>
                        <Col md="3">
                            Published
                        </Col>
                    </Row>
                </Container>
                {listingSearchData.data !== undefined ?  listingSearchData.data.adminSearchListing.map(listing => {
                    return (
                        <ListingCard listingName={listing.title} published={listing.published} setRedirect={setRedirect} setPath={setPath} id={listing.id} />
                    )
                }) : null }
            </Container>
        </div>
    )
}

const ADMIN_SEARCH_LISTING = gql`
    query adminSearchListing($search: String!) {
        adminSearchListing(search: $search) {
            id
            title
            published
        }
    }
`

export default ListingSearch
import React, { Component } from 'react';
import './css/dashboard.css';
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Jumbotron
} from 'reactstrap';
import Logo from '../images/logo.png';
import { Link, Redirect } from 'react-router-dom';
import Navigation from './Navigation';

export default class Dashboard extends Component {
  render() {
    if (localStorage.getItem('jwt') == null) {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <Navigation />
        <Container className="dashboardComingSoon">
          <Jumbotron>
            <h1>Dashboard coming soon</h1>
          </Jumbotron>
        </Container>
      </div>
    );
  }
}

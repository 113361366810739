import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  ModalBody,
} from "reactstrap";
import "./css/userDisplay.css";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import Loading from "./loading";
import { Redirect } from 'react-router-dom';
import Message from "./message"

const UserDisplay = (props) => {

  const [redirect, setRedirect] = useState(false);
  const [path, setPath] = useState('');

  const user = props.user;
  const listings = props.listings;
  const host = props.host;

  const [userVerified, setUserVerified] = useState(user.verified);
  const [hostVerified, setHostVerified] = useState(host.hostVerified);
  const [userVerifiedAccept, userAccept] = useMutation(
    ADMIN_VERIFY_USER_ACCEPT_QUERY
  );
  const [userVerifyDeny, userDeny] = useMutation(ADMIN_VERIFY_USER_DENY_QUERY);
  const [hostVerifyAccept, hostAccept] = useMutation(ADMIN_VERIFY_HOST_ACCEPT);
  const [hostVerifyDeny, hostDeny] = useMutation(ADMIN_VERIFY_HOST_DENY);
  const [addListingMutation, addListingQuery] = useMutation(ADMIN_ADD_LISTING)
  const [modal, setModal] = useState(false)
  const [deleteUser, deletedUser] = useMutation(DELETE_USER)
  const [userId, setUserId] = useState("")
  const [message, setMessage] = useState({color: "success", message: ""})

  // Check for loading
  if (
    userAccept.loading ||
    userDeny.loading ||
    hostAccept.loading ||
    hostDeny.loading
  ) {
    return <Loading />;
  }

  const editButton = () => {
    try {
      let newPath = "/users/edit/" + props.user.id
      setPath(newPath)
      setRedirect(true)
    }catch(err) {
      console.log(err)
    }
  }

  const openModal = async(id) => {
    try{
      setUserId(id)
      setModal(true)
    }catch(err) {
      console.log(err)
    }
  }

  const toggle = async() => {
    try{
      setModal(!modal)
    }catch(err) {
      console.log(err)
    }
  }

  const deletedUserRequest = async() => {
    try{
      await deleteUser({variables: {"userId": props.user.id}})
      setMessage({color: "success", message: "Deleted the user succcesfully"})
      setModal(false)
      setPath("/users")
      setRedirect(true)

    }catch(err) {
      setMessage({color: "danger", message: err.graphQLErrors[0].message})
      window.scrollTo(0,0)
    }
  }

  const addListing = async() => {
    try{
      const userId = props.user.id
      await addListingMutation({variables: {userId}})
      setMessage({color: "success", message: "Successfully created new listing"})
      
    }catch(err) {
      setMessage({color: "danger", message: err.graphQLErrors[0].message})
      window.scrollTo(0,0)
    }
  }

  if(redirect) {
    return <Redirect to={path}/>
  }

  // Check for errors

  return (
    <Container fluid="true">
      <Message message={message.message} color={message.color}/>
      <h1 className="text-center">
        {user.firstName} {user.lastName}
      </h1>
      <Row>
        <Col md='6'>
          <Button className="align-right padding-user-display-button" color="primary" onClick={editButton}>Edit</Button>
        </Col>
        <Col md='6'>
          <Button className="padding-user-display-button" color="danger" onClick={openModal}>Delete</Button>
        </Col>
      </Row>
      <Row>
        <Col md="4" className="">
          <img
            className="profilePicture"
            src={user.profilePicture}
            alt="The users profile"
          />
        </Col>
        <Col md="4">
          <Card>
            <CardBody>
              <CardTitle>User Details</CardTitle>
              <ul>
                <li>First Name: {user.firstName}</li>
                <li>Last Name: {user.lastName}</li>
                <li>Email: {user.email}</li>
                <li>PhoneNumber: {user.phoneNumber}</li>
                <li>Gender: {user.gender}</li>
                <li>
                  Date Of Birth: {new Date(Number(user.dateOfBirth)).toString()}
                </li>
                <li>Marriage Status: {user.marriageStatus}</li>
              </ul>
              <CardTitle>Emergency Contact</CardTitle>
              <ul>
                <li>First Name: {user.emergencyContact.firstName}</li>
                <li>Last Name: {user.emergencyContact.lastName}</li>
                <li>Email: {user.emergencyContact.email}</li>
                <li>Phone Number: {user.emergencyContact.phoneNumber}</li>
              </ul>
            </CardBody>
          </Card>
        </Col>
        <Col md="4" className="text-center">
          <h2>User: {userVerified ? "Verified" : "Not Verified"}</h2>
          <Button
            color="primary"
            onClick={() => {
              userVerifiedAccept({ variables: { userId: user.id } });
              setUserVerified(true);
            }}
          >
            Verify
          </Button>
          <Button
            className="ml-4"
            color="danger"
            onClick={() => {
              userVerifyDeny({ variables: { userId: user.id } });
              setUserVerified(false);
            }}
          >
            Deny
          </Button>
          <h2 className="mt-5">
            Host: {hostVerified ? "Verified" : "Not Verified"}
          </h2>
          <Button
            color="primary"
            onClick={() => {
              hostVerifyAccept({ variables: { userId: user.id } });
              setHostVerified(true);
            }}
          >
            Verify
          </Button>
          <Button
            className="ml-4"
            color="danger"
            onClick={() => {
              hostVerifyDeny({ variables: { userId: user.id } });
              setHostVerified(false);
            }}
          >
            Deny
          </Button>
        </Col>
        <Col md="4" className="mt-5 text-center">
          <h3>Passport Number:</h3>
          <p>{user.passportNumber}</p>
          <div className="text-center">
            <h3>Passport photo:</h3>
          </div>
          <img className="governmentId" src={user.governmentId}></img>
        </Col>
        <Col md="4" className="mt-5">
          <Button className='mb-3' color="warning" onClick={addListing} >Add Listing</Button>
          {listings.map((list) => {
            return (
              <div>
                <Button color='primary' onClick={() => {
                  setRedirect(true);
                  setPath(`/users/listing/edit/${list.id}`)
                }
                  
                }>
                  Edit Listing
                </Button>
                <h4>Property And Guest</h4>
                <ul>
                  <li>Kind of place: {list.type.map((t) => t + ", ")}</li>
                  <li>Number of guest: {list.guest}</li>
                  <li>Sleeping arrangements: need to deal with later</li>
                </ul>
                <h4>Location</h4>
                <ul>
                  <li>Apartment/Suite: {list.location.apartmentSuite}</li>
                  <li>Street Address: {list.location.streetAddress}</li>
                  <li>City: {list.location.city}</li>
                  <li>State: {list.location.state}</li>
                  <li>Post code: {list.location.postCode}</li>
                  <li>Country: {list.location.country}</li>
                  <li>Longitude: {list.location.longitude}</li>
                  <li>Latitude: {list.location.latitude}</li>
                </ul>
                <h4>Amenities</h4>
                <ul>
                  <li>Wifi: {list.wifi ? "True" : "False"}</li>
                  <li>Shower: {list.shower ? "True" : "False"}</li>
                  <li>Cooking facilities: {list.cooking ? "True" : "False"}</li>
                  <li>TV: {list.TV ? "True" : "False"}</li>
                  <li>Coffee: {list.coffee ? "True" : "False"}</li>
                  <li>Kitchen: {list.kitchen ? "True" : "False"}</li>
                  <li>
                    Private Lounge: {list.privateLounge ? "True" : "False"}
                  </li>
                  <li>
                    Laundry - Washing:
                    {list.landryWashing ? "True" : "False"}
                  </li>
                  <li>Landry - Dryer: {list.landryDryer ? "True" : "False"}</li>
                  <li>Pool: {list.pool ? "True" : "False"}</li>
                  <li>Hot Tub: {list.hotTub ? "True" : "False"}</li>
                </ul>
                <h4>Images</h4>
                {list.images.map((img) => {
                  return <img src={img} className="listingImage mb-3" />;
                })}
                <h4>Place</h4>
                <p>Description: {list.place}</p>
                <h4>Title: {list.title}</h4>
                <h4>House Rules</h4>
                <ul>
                  <li>
                    Suitable for children:{" "}
                    {list.children === "tick" ? "True" : "False"}
                  </li>
                  <li>
                    Suitable for infants:{" "}
                    {list.infants === "tick" ? "True" : "False"}
                  </li>
                  <li>
                    Smoking Allowed:{" "}
                    {list.smoking === "tick" ? "True" : "False"}
                  </li>
                  <li>
                    Alcohol allowed:{" "}
                    {list.alcohol === "tick" ? "True" : "False"}
                  </li>
                  <li>
                    We have children: {list.haveChildren ? "True" : "False"}
                  </li>
                  <li>We have pets: {list.havePets ? "True" : "False"}</li>
                  <li>No parking: {list.noParking ? "True" : "False"}</li>
                  <li>Shared Space: {list.sharedSpace ? "True" : "False"}</li>
                  <li>Additional Rules: {list.additionalRules}</li>
                  <li>Gender Preference: {list.preferedGender}</li>
                  <li>Maritial Preference: {list.preferedMaritial}</li>
                  <li>Price: {list.price}</li>
                  <li>Currency: {list.currency}</li>
                </ul>
                <h4>Calendar</h4>
                <ul>
                  <li>Check in time: {list.checkInFrom}</li>
                  <li>Check out time: {list.checkInTo}</li>
                  <li>Min nights: {list.minNights}</li>
                  <li>Max nights: {list.maxNights}</li>
                </ul>
              </div>
            );
          })}
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <h2 className="text-center">Are you sure you want to delete this user</h2>
          <Row>
            <Col md="10">
              <Button color="danger" onClick={deletedUserRequest}>Yes</Button>
            </Col>
            <Col md="2">
              <Button color="primary" onClick={toggle}>No</Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Container>
  );
};

// Create the queries for the buttons

const ADMIN_VERIFY_USER_ACCEPT_QUERY = gql`
  mutation adminVerifyUserAccept($userId: String!) {
    adminVerifyUserAccept(userId: $userId) {
      id
    }
  }
`;

const ADMIN_VERIFY_USER_DENY_QUERY = gql`
  mutation adminVerifyUserDeny($userId: String!) {
    adminVerifyUserDeny(userId: $userId) {
      id
    }
  }
`;

const ADMIN_VERIFY_HOST_ACCEPT = gql`
  mutation adminVerifyHostAccept($userId: String!) {
    adminVerifyHostAccept(userId: $userId) {
      id
    }
  }
`;

const ADMIN_VERIFY_HOST_DENY = gql`
  mutation adminVerifyHostDeny($userId: String!) {
    adminVerifyHostDeny(userId: $userId) {
      id
    }
  }
`;

const DELETE_USER = gql`
  mutation adminDeleteUser($userId: String!) {
    adminDeleteUser(userId: $userId) {
      id
    }
  }
`

const ADMIN_ADD_LISTING = gql`
  mutation adminAddListing($userId: ID!){
    adminAddListing(userId: $userId) {
      id
    }
  }
`

export default UserDisplay;

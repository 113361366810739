import React, { useEffect, useState } from "react"
import Navigation from "./Navigation"
import SingleImage from "../components/singleImage"
import "./css/imageContainer.css"
import { useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import AddImage from "../components/addImage"
import { Container, Modal, ModalBody, ModalHeader, Button} from "reactstrap"

const ImageContainer = (props) => {
    const listingId = props.match.params.id
    const jwt = localStorage.getItem('jwt')


    const [listingQuery, listingData] = useLazyQuery(ADMIN_GET_SINGLE_LISTING, {fetchPolicy: "network-only"})
    const [deleteIndex, setDeleteIndex] = useState(-1)
    const [showModal, setShowModal] = useState(false)
    const [entered, setEntered] = useState(false)

    useEffect(() => {
        listingQuery({variables: {listingId}})
    }, [])

    const onFileDrop = async(e) => {
        try{
            e.stopPropagation();
            e.preventDefault();
            setEntered(false)
            const files = e.dataTransfer.files
            for(let i = 0; i < files.length; i++) {
                let file = files[i]
                let fileReader = new FileReader();

                fileReader.onload = async() =>{
    
                    await fetch("/api/admin/images/listing", {
                        method: "POST",
                        headers: {
                            "Authorization": "Bearer " + jwt,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            imageData: fileReader.result,
                            listingId
                        })
                    })
    
                    listingQuery({variables: {listingId}})
                }
                fileReader.readAsDataURL(file);
            }
            
        }catch(err) {
            console.log(err)
        }
    }

    const onDragOver = async(e) => {
        try{
            e.preventDefault()
            setEntered(true)

        }catch(err) {
            console.log(err)
        }
    }

    const onDragEnter = async(e) => {
        try{
            e.preventDefault()
            setEntered(true)
        }catch(err) {
            console.log(err)
        }
    }

    const onDragleave = async(e) => {
        try{
            e.preventDefault()
            setEntered(false)
        }catch(err) {
            console.log(err)
        }
    }

    const onImageClick = async(index) => {
        try{
            setShowModal(true)
            setDeleteIndex(index)
        }catch(err) {
            console.log(err)
        }
    }

    const onCloseModal = async() => {
        try{
            setShowModal(false)
        }catch(err) {
            console.log(err)
        }
    }


    const removeImage = async() => {
        try{
            setShowModal(false)

            // Send a delete request
            const deleteReq = await fetch("/api/admin/images/delete", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + jwt
                },
                body: JSON.stringify({listingId, deleteIndex})
            })

            const res = await deleteReq.json()

            if(res['success'] ===  "successfully deleted the image") {
                //set a message in here
                listingQuery({variables: {listingId}})

            } else {
                // set error message
            }
            
        }catch(err) {
            console.log(err)
        }
    }

    return (
        <div>
            <Navigation />
            <h1 className="text-center mt-5 mb-5">Add Images To Listing</h1>
            <div className="image-container-grid">
                {listingData.data !== undefined ? listingData.data.adminGetSingleListing.images.map((image, i) => {
                    return (
                        <SingleImage onImageClick={onImageClick} imageLink={image} index={i}/>
                    )
                }) : null}
               
            </div>
            <AddImage entered={entered} onFileDrop={onFileDrop} onDragEnter={onDragEnter} onDragOver={onDragOver} onDragleave={onDragleave} />
            <Modal isOpen={showModal}>
                <ModalHeader>Are you sure you want to delete this image</ModalHeader>
                <ModalBody>
                    <div className='image-container-button-confirm'>
                        <Button color='danger' onClick={removeImage} >Yes</Button>
                        <Button color='primary' onClick={onCloseModal} >No</Button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
    
}

const ADMIN_GET_SINGLE_LISTING = gql`
        query adminGetSingleListing($listingId: ID!) {
            adminGetSingleListing(listingId: $listingId) {
                images
            }
        }
    `


export default ImageContainer
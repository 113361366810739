import React from 'react'
import "./css/addImage.css"

const AddImage = ({onFileDrop, onDragOver, onDragEnter, onDragLeave, entered}) => {

    return (
        <div className='add-image-container' style={{border: entered ? "2px solid red" : "2px solid black"}}   onDrop={onFileDrop} onDragOver={onDragOver} onDragEnter={onDragEnter} onDragLeave={onDragLeave}>
            <p>Drop Here</p>
        </div>
    )
}

export default AddImage
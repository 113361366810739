import React from "react"
import {Container, Row, Col, Button} from "reactstrap"
import "./css/listingCard.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons'

const ListingCard = ({listingName, id, published, setRedirect, setPath}) => {
    
    return (
        <Container className="mt-5 mb-5 listing-card-container">
            <Row className="text-center">
                <Col md="3">
                    {listingName}
                </Col>
                <Col md="3">
                    <Button color="success" onClick={() => {
                        setPath("/users/listing/edit/" + id)
                        setRedirect(true)
                    }}>View</Button>
                </Col>
                <Col md="3">
                    <Button color="primary" onClick={() => {
                        setPath("/users/listing/edit/" + id)
                        setRedirect(true)
                    }}>Edit</Button>
                </Col>
                <Col md="3">
                    {published ? <FontAwesomeIcon icon={faCheck} style={{color: "green"}} /> : <FontAwesomeIcon icon={faX} style={{color: "red"}} />   }
                </Col>
            </Row>
        </Container>
    )
}

export default ListingCard
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import Dashboard from './containers/Dashboard';
import Users from './containers/Users';
import ViewUser from './containers/ViewUser';
import Login from './containers/Login';
import ApolloClient, { FetchType } from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import SingleUser from './containers/singleUser';
import UserVerificationList from './containers/UserVerificationList';
import HostVerificationList from './containers/HostVerificationList';
import EditUser from './containers/editUser'
import AddUser from './containers/addUser'
import Notifications from './containers/notifications'
import ResetPassword from './containers/resetPassword'
import EditListing from './containers/editListing'
import AllUsers from './containers/allUsers'
import AllHosts from './containers/allHosts'
import ListingNav from "./containers/listingNav"
import AllListings  from "./containers/allListings"
import ListingSearch from "./containers/listingSearch"
import ImageContainer from "./containers/imageContainer"

const App = () => {
  const [jwt, setJwt] = useState("");
  const[loggedIn, setLoggedIn] = useState("")

  useEffect(() => {
    setJwt(localStorage.getItem('jwt'))
    setLoggedIn(localStorage.getItem("loggedIn"))
  }, [])


  const client = new ApolloClient({
    uri: 'https://yesh.host/graphql',
    headers: { authorization: 'Bearer: ' + jwt },
  });

  // Is used when logged in as it does not reset the apollo client and therefore auth does not work
  const reloadApp = async () => {
    try{
      setLoggedIn(true)  
      localStorage.setItem("loggedIn", "false")  
      window.location.reload(false);
    }catch(err) {
      console.log(err)
    }
  }

  return (
    <ApolloProvider client={client}>
      <Router>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/users" component={Users} />
        <Route exact path="/users/view" component={ViewUser} />
        <Route exact path="/users/all" component={AllUsers} />
        <Route exact path="/users/hosts" component={AllHosts} />
        <Route exact path="/login" render={props => <Login reloadApp={reloadApp} loggedIn={loggedIn} />} />
        <Route exact path="/users/verify" component={UserVerificationList} />
        <Route
          exact
          path="/users/hostVerify"
          component={HostVerificationList}
        />
        <Route exact path="/user/:id" component={SingleUser} />
        <Route path="/users/edit/:id"  component={EditUser} />
        <Route exact path="/users/add" component={AddUser} />
        <Route exact path="/notifications" component={Notifications} />
        <Route path='/users/reset/:id' component={ResetPassword} />
        <Route path='/users/listing/edit/:id' component={EditListing} />
        <Route exact path="/listing" component={ListingNav} /> 
        <Route exact path="/listing/all" component={AllListings} />
        <Route exact path="/listing/search" component={ListingSearch} />
        <Route exact path='/listing/images/:id' component={ImageContainer} />
      </Router>
    </ApolloProvider>
  );
};

export default App;

import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';

const UserButton = (props) => {
  console.log(props);
  const [path, setPath] = useState('');
  const [redirect, setRedirect] = useState(false);

  if (redirect) {
    return <Redirect path={props.Link} />;
  }

  return (
    <div>
      <Link to={props.link}>
        <Button>{props.buttonText}</Button>
      </Link>
    </div>
  );
};

export default UserButton;

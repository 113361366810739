import React, { useState } from 'react';
import Navigation from './Navigation';
import UserButton from '../components/UserButton';

const Users = () => {
  return (
    <div>
      <Navigation />
      <UserButton buttonText="View user" link="/users/view" />
      <UserButton buttonText="Users To Verify" link="/users/verify" />
      <UserButton buttonText="Host To Verify" link="/users/hostVerify" />
      <UserButton buttonText="Add new user" link="/users/add" />
      <UserButton buttonText="All Users" link="/users/all" />
      <UserButton buttonText="Host" link="/users/hosts" />
    </div>
  );
};

export default Users;

import React, { useEffect, useState } from "react"
import { useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Navigation from "./Navigation"
import ListingCard from "../components/listingCard"
import {Container, Row, Col, Button} from "reactstrap"
import { Redirect } from 'react-router-dom';



const AllListings = () => {

    const [listingQuery, listingData] = useLazyQuery(ADMIN_GET_ALL_LISTINGS)
    const [redirect, setRedirect] = useState(false)
    const [path, setPath] = useState("")

    useEffect(() => {
        listingQuery()
    }, [])
    
    

    if (redirect) {
        return <Redirect to={path} />;
    }
    return (
        <div>
            <Navigation />
            <Container>
                <h1 className="text-center">All Listings</h1>
                <Container className="mt-5 mb-5">
                    <Row className="text-center">
                        <Col md="3">
                            Listing Name
                        </Col>
                        <Col md="3">
                            View
                        </Col>
                        <Col md="3">
                            Edit
                        </Col>
                        <Col md="3">
                            Published
                        </Col>
                    </Row>
                </Container>
                {listingData.data !== undefined ?  listingData.data.adminGetAllListings.map(listing => {
                    return (
                        <ListingCard listingName={listing.title} published={listing.published} setRedirect={setRedirect} setPath={setPath} id={listing.id} />
                    )
                }) : null }
            </Container>
        </div>
        
    )
}

const ADMIN_GET_ALL_LISTINGS = gql`
    query adminGetAllListings {
        adminGetAllListings {
            id
            title
            published
        }
    }
`

export default AllListings
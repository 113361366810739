import React from 'react'
import Navigation from './Navigation';
import UserButton from '../components/UserButton';

const ListingNav = () => {
    return (
        <div>
            <Navigation />
            <UserButton  buttonText="Search Listing" link="/listing/search" />
            <UserButton  buttonText="All Listings" link="/listing/all" />
        </div>
    )
}

export default ListingNav
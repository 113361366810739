import React, { Component } from 'react';
import Navigation from './Navigation';
import UserSearch from '../components/UserSearch';

export default class ViewUser extends Component {
  render() {
    return (
      <div>
        <Navigation />
        <UserSearch />
      </div>
    );
  }
}

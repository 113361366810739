import React from "react"

const SingleImage = ({imageLink, onImageClick, index}) => {
    return (
        <div onClick={() => onImageClick(index)} style={{}}>
            <img src={imageLink} />
        </div>
    )
}

export default SingleImage
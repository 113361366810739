import React from 'react'
import Nav from 'reactstrap/lib/Nav'
import Navigation from './Navigation'
import ResetPasswordContent from '../components/resetPasswordContent'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'


const ResetPassword = (props) => {
  const [resetPassword, resetPasswordData] = useMutation(ADMIN_RESET_PASSWORD)

  return(
    <div>
      <Navigation />
      <ResetPasswordContent resetPassword={resetPassword} userId={props.match.params.id}/>
    </div>
  )
}

const ADMIN_RESET_PASSWORD = gql`
  mutation adminResetUserPassword($userId: String!, $password: String!) {
    adminResetUserPassword(userId: $userId, password: $password) {
      id
    }
  }
`

export default ResetPassword
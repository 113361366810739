import React, { useState } from 'react'
import Navigation from './Navigation'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'
import Loading from '../components/loading'
import EditListingForm from "../components/editListingForm"

const EditListing = (props) => {
    const {loading, error, data} = useQuery(ADMIN_GET_USER_LISTING, {variables: {listingId: props.match.params.id}})
    const [editListing, {loading: editListingLoading, error: editListingError}] = useMutation(ADMIN_EDIT_LISTING)

    if(loading) {
        return <Loading />
    }


    return (
        <div>
            <Navigation />
            <EditListingForm listing={data.adminGetUserListing} editListing={editListing} listingId={props.match.params.id} />
        </div>
    )
}

const ADMIN_GET_USER_LISTING = gql`
    query adminGetUserListing($listingId: String!) {
        adminGetUserListing(listingId: $listingId) {
            type
            guest
            location {
                country
                city
                streetAddress
                apartmentSuite
                state
                postCode
                longitude
                latitude
            }
            TV
            coffee
            cooking
            hotTub
            kitchen
            landryDryer
            landryWashing
            pool
            privateLounge
            shower
            wifi
            place
            title
            children
            infants
            smoking
            alcohol
            haveChildren
            havePets
            noParking
            sharedSpace
            additionalRules
            preferedGender
            preferedMaritial
            price
            currency
            upToDateCalender
            checkInFrom
            checkInTo
            minNights
            maxNights
            place
            title
            preferedGender
            preferedMaritial
            sleepingArrangement {
                single
                double
                queen
                sofaBed
                floorMattress
                airMattress
                toddlerBed
            }
            published
        }
    }
`

const ADMIN_EDIT_LISTING = gql`
    mutation adminEditListing($listing: listing!, $listingId: String!) {
        adminEditListing(listing: $listing, listingId: $listingId) {
            id
        }
    }
`


export default EditListing;
import React, { Component } from 'react';
import LoginFields from '../components/LoginFields';
import './css/login.css';
import { Redirect } from 'react-router-dom';

export default class Login extends Component {

  
  render() {
    if(this.props.loggedIn) {
      return <Redirect path='/' /> 
    }
    return (
      <div className="center">
        <LoginFields reloadApp={this.props.reloadApp} />
      </div>
    );
  }
}

import React, { useEffect, useState } from 'react';
import Navigation from './Navigation';
import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import Loading from '../components/loading';
import { Container } from 'reactstrap';
import './css/userVerificationList.css';
import { Redirect } from 'react-router-dom';

const UserVerificationList = () => {
  const [adminGuestAwaitingVerificationQuery, awaitGuest] = useLazyQuery(
    ADMIN_GUEST_AWAITING_VERIFICATION
  );
  const [redirect, setRedirect] = useState(false);
  const [path, setPath] = useState('');
  const [dataSet, setDataSet] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    adminGuestAwaitingVerificationQuery();
  }, []);
  if (awaitGuest.loading) {
    return <Loading />;
  }

  if (awaitGuest.data && !dataSet) {
    // The problem is that we get here but the data needs to have changed which is has not
    setUsers(awaitGuest.data.adminGuestAwaitingVerification);
    setDataSet(true);
  }

  if (redirect) {
    return <Redirect to={path} />;
  }

  return (
    <div>
      <Navigation />
      <Container>
        <h2 className="text-center mt-5">User verification</h2>
        {users.map((user) => {
          return (
            <div
              key={user.id}
              className="userButton"
              onClick={() => {
                setPath(`/user/${user.id}`);
                setRedirect(true);
              }}
            >
              <p>
                {user.firstName} {user.lastName}
              </p>
            </div>
          );
        })}
      </Container>
    </div>
  );
};

const ADMIN_GUEST_AWAITING_VERIFICATION = gql`
  {
    adminGuestAwaitingVerification {
      id
      firstName
      lastName
    }
  }
`;

export default UserVerificationList;

import React, {useState} from 'react'
import { Form, FormGroup, Label, Input, FormText, Row, Container, Col, Button } from 'reactstrap'
import Config from '../config'
import "./css/editUserContent.css"
import Message from './message'

const EditUserContent = ({userData, editUser, error}) => {
  const dateOfBirth = new Date(0)
  dateOfBirth.setUTCMilliseconds(userData.dateOfBirth)
  const year = dateOfBirth.getFullYear()
  const month = dateOfBirth.getMonth() < 9 ? "0"+  (dateOfBirth.getMonth() + 1)  : (dateOfBirth.getMonth() + 1)  
  const date = dateOfBirth.getDate() < 10 ? "0" + dateOfBirth.getDate() : dateOfBirth.getDate()

  const [formData, setFormData] = useState({firstName: userData.firstName, lastName:userData.lastName, gender: userData.gender, marriageStatus: userData.marriageStatus, 
    email: userData.email, dateOfBirth: year + "-" + month + '-' + date, passportNumber: userData.passportNumber, governmentId: userData.governmentId, phoneCode: userData.phoneCode, 
    phoneNumber: userData.phoneNumber, ecFirstName: userData.emergencyContact.firstName, ecLastName: userData.emergencyContact.lastName, ecEmail: userData.emergencyContact.email, description: userData.description,
    languages: userData.languages, displayLocation: userData.displayLocation})
  const [profilePicture, setProfilePicture] = useState(userData.profilePicture)
  const [governmentId, setGovernmentId] = useState(userData.governmentId)
  const [message, setMessage] = useState({color: "success", message: ""})

  const config = new Config()

  const changeFormData = async(e) => {
    try{
      let newFormData = Object.assign({}, formData)
      newFormData[e.target.name] = e.target.value
      setFormData(newFormData)
    }catch(err) {
      console.log(err)
    }
  }

  const submitForm = async(e) => {
    try{
      e.preventDefault();
      const {firstName, lastName, email, phoneNumber, phoneCode, ecFirstName, ecLastName, ecEmail, gender, description, languages, displayLocation, dateOfBirth, passportNumber, marriageStatus} = formData
      const emergencyContact = {
        firstName: ecFirstName,
        lastName: ecLastName,
        email: ecEmail
      }

      console.log(gender)
      console.log(marriageStatus)

      const formattedDOB = new Date(dateOfBirth)
      await editUser({variables: {userId: userData.id, firstName, lastName, email, phoneNumber, phoneCode, gender, description, languages, displayLocation, dateOfBirth: formattedDOB, passportNumber: passportNumber === null ? "" : passportNumber, marriageStatus, emergencyContact}}, {errorPolicy: "all"})

      setMessage({color: "success", message: "Successfully edited the user"})
      window.scrollTo(0,0)

      
    }catch(err) {
      console.log("error messages are here")
      console.log(err.graphQLErrors[0].message)
      setMessage({color: "danger", message: err.graphQLErrors[0].message})
    }
  }

  const uploadProfilePicture = async(e) => {
    try{
      e.preventDefault();
      const file = e.target.files[0];
      const binaryData =  await new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = (event) => {
              resolve(event.target.result);
          };

          reader.onerror = (err) => {
              reject(err);
          };

          reader.readAsDataURL(file);
      });

      const jwt = localStorage.getItem("jwt")

      const newProfilePicture = await fetch(config.PROXY + "/admin/images/profilePicture", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt
        },
        body: JSON.stringify({imageData: binaryData, userId: userData.id})
      })

      const response = await newProfilePicture.json();

      setProfilePicture(response.profilePicture)
      
      
    }catch(err) {
      console.log(err)
    }
  }

  const uploadGovernmentId = async(e) => {
    try{
      e.preventDefault();
      console.log(e.target.files)
      const file = e.target.files[0];
      const binaryData =  await new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = (event) => {
              resolve(event.target.result);
          };

          reader.onerror = (err) => {
              reject(err);
          };

          reader.readAsDataURL(file);
      });

      const jwt = localStorage.getItem("jwt")

      const newGovernmentId = await fetch(config.PROXY + "/admin/images/governmentId", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt
        },
        body: JSON.stringify({imageData: binaryData, userId: userData.id})
      })

      const response = await newGovernmentId.json();

      console.log(response)

      setGovernmentId(response.governmentId)
      
      
    }catch(err) {
      console.log(err)
    }
  }

  const addLanguage = async() => {
    try{  
      let newFormData = Object.assign({},formData);
      newFormData.languages.push("");
      console.log(newFormData.languages)
      setFormData(newFormData)
    
    }catch(err) {
      console.log(err)
    }
  }

  const languageChange = async(e, index) => {
    try{
      let newFormData = Object.assign({}, formData)
      newFormData.languages[index] = e.target.value
      setFormData(newFormData)
    }catch(err) {
      console.log(err)
    }
  }

  const removeLanguages = async(index) => {
    try{  
      let newFormData = Object.assign({}, formData)
      newFormData.languages = newFormData.languages.filter((lang, i) => {
        return i !== index
      })
      setFormData(newFormData)
    }catch(err) {
      console.log(err)
    }
  }

  return(
    <Container>
      <Message color={message.color} message={message.message} />
      <h1 className="text-center mt-4">Edit User</h1>
      <Form className="mt-4 mb-5" onSubmit={submitForm}>
        <FormGroup>
          <Label for="firstName" >First Name</Label>
          <Input type="text" required name="firstName" id="firstName" placeholder="First Name" value={formData.firstName} onChange={changeFormData} />
        </FormGroup>
        <FormGroup>
          <Label for="lastName" >Last Name</Label>
          <Input type="text" required name="lastName" id="lastName" placeholder="Last Name" value={formData.lastName} onChange={changeFormData} />
        </FormGroup>
        <FormGroup>
          <Label for="gender">Gender</Label>
          <Input type="select" name="gender" id="gender" onChange={changeFormData} value={formData.gender}>
            <option>Male</option>
            <option>Female</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="marriageStaus">Marriage Status</Label>
          <Input type="select" name="marriageStatus" id="marrigeStaus" onChange={changeFormData} value={formData.marriageStatus}>
            <option>Married</option>
            <option>Unmarried</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input type="email" required name="email" id="email" placeholder="Email" value={formData.email} onChange={changeFormData} />
        </FormGroup>
        <FormGroup>
          <Label for="dob" >Date Of Birth</Label>
          <Input type="date" name="dateOfBirth" id="dob" placeholder="Date Of Birth" value={formData.dateOfBirth} onChange={changeFormData} />
        </FormGroup>
        <FormGroup>
          <Label for="passportNumber" >Passport Number</Label>
          <Input type="text" name="passportNumber" id="passportNumber" placeholder="Passport Number" value={formData.passportNumber} onChange={changeFormData} />
        </FormGroup>
        {/* Will Put the file input in last */}
        <Row>
          <Col md="4">
            <FormGroup>
              <Label for="phoneCode" >Phone Code</Label>
              <Input type="text" required name="phoneCode" id="phoneCode" placeholder="PhoneCode" value={formData.phoneCode} onChange={changeFormData} />
            </FormGroup>
          </Col>
          <Col md="8">
            <FormGroup>
              <Label for="phoneNumber" >Phone Number</Label>
              <Input type="text"  required name="phoneNumber" id="phoneNumber" placeholder="Phone Number" value={formData.phoneNumber} onChange={changeFormData} />
            </FormGroup>
          </Col>
        </Row>
        <h2 className="text-center mt-4">Emergency Contact</h2>
        <FormGroup>
          <Label for="ecFirstName" >First Name</Label>
          <Input type="text" name="ecFirstName" id="ecFirstName" placeholder="First Name" value={formData.ecFirstName} onChange={changeFormData} />
        </FormGroup>
        <FormGroup>
          <Label for="ecLastName" >Last Name</Label>
          <Input type="text" name="ecLastName" id="ecLastName" placeholder="Last Name" value={formData.ecLastName} onChange={changeFormData} />
        </FormGroup>
        <FormGroup>
          <Label for="ecEmail" >Email</Label>
          <Input type="email" name="ecEmail" id="ecEmail" placeholder="Email" value={formData.ecEmail} onChange={changeFormData} />
        </FormGroup>
        <h2 className="text-center">Profile Information</h2>
        <FormGroup>
          <Label for="description" >About Me</Label>
          <Input type="textarea" name="description" id="description" placeholder="About Me" value={formData.description} onChange={changeFormData} />
        </FormGroup>
        {/* If there is no data add the first one */}
        {formData.languages.length == 0 ? 
          <FormGroup>
            <Label for="languages">Languages I can Speak</Label>
            <Input type="select" name="languages" id="languages" value={formData.languages[0]} onChange={(e) => languageChange(e, 0)}>
              {languages.map(lang => {
                return (
                  <option>{lang}</option>
                )
              })}
            </Input>
          </FormGroup> : null}
        {formData.languages.map((lan, i) => {
          return (
            <FormGroup>
              <Label for="languages">Languages I can Speak</Label>
              <Row>
               <Col md="10">
                <Input type="select" name="languages" id="languages" value={lan} onChange={(e) => languageChange(e, i)}>
                  {languages.map(lang => {
                    return (
                      <option>{lang}</option>
                    )
                  })}
                </Input>
               </Col>
               <Col md="2">
                  <Button color="danger" onClick={() => removeLanguages(i)}>Delete</Button>
               </Col>
              </Row>
            </FormGroup>
          )
        })}
        <Button color="primary" className="euc-add-btn" onClick={addLanguage}>+</Button>
        <FormGroup>
          <Label for="displayLocation" >Locations I Am From</Label>
          <Input type="text" name="displayLocation" id="displayLocation" placeholder="Location" value={formData.displayLocation} onChange={changeFormData} />
        </FormGroup>
        <Button color="primary">Edit</Button>
      </Form>

      <Form className="mb-5">
        <Row>
          <Col md="6">
            <img className="img-fluid" src={governmentId} alt="Government Id" />
          </Col>
          <Col md="6">
            <FormGroup>
              <Label for="governmentId">Government Id</Label>
              <Input type="file" name="governmentId" id="governmentId" onChange={uploadGovernmentId} />
              <FormText color="muted">
                Add The users government id
              </FormText>
            </FormGroup>
          </Col>
          <Col md="6">
            <img className="img-fluid" src={profilePicture} alt="Profile"  />
          </Col>
          <Col md="6">
            <FormGroup>
              <Label for="profilePicture">Profile Picture</Label>
              <Input type="file" name="prodfilePicture" id="profilePicture"  onChange={uploadProfilePicture}/>
              <FormText color="muted">
                Users profile picture
              </FormText>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}



const languages = [
  "Afrikaans",
  "Albanian",
  "Amharic",
  "Arabic",
  "Armenian",
  "Azerbaijani",
  "Bangla",
  "Basque",
  "Belarusian",
  "Bosnian",
  "Bulgarian",
  "Burmese",
  "Catalan",
  "Cebuano",
  "Chinese (Simplified)",
  "Chinese (Traditional)",
  "Corsican",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "English",
  "Esperanto",
  "Estonian",
  "Filipino",
  "Finnish",
  "French",
  "Galician",
  "Georgian",
  "German",
  "Greek",
  "Gujarati",
  "Haitian Creole",
  "Hausa",
  "Hawaiian",
  "Hebrew",
  "Hindi",
  "Hmong",
  "Hungarian",
  "Icelandic",
  "Igbo",
  "Indonesian",
  "Irish",
  "Italian",
  "Japanese",
  "Javanese",
  "Kannada",
  "Kazakh",
  "Khmer",
  "Korean",
  "Kurdish",
  "Kyrgyz",
  "Lao",
  "Latin",
  "Latvian",
  "Lithuanian",
  "Luxembourgish",
  "Macedonian",
  "Malagasy",
  "Malay",
  "Malayalam",
  "Maltese",
  "Maori",
  "Marathi",
  "Mongolian",
  "Nepali",
  "Norwegian",
  "Nyanja",
  "Pashto",
  "Persian",
  "Polish",
  "Portuguese",
  "Punjabi",
  "Romanian",
  "Russian",
  "Samoan",
  "Scottish Gaelic",
  "Serbian",
  "Shona",
  "Sindhi",
  "Sinhala",
  "Slovak",
  "Slovenian",
  "Somali",
  "Southern Sotho",
  "Spanish",
  "Sundanese",
  "Swahili",
  "Swedish",
  "Tajik",
  "Tamil",
  "Telugu",
  "Thai",
  "Turkish",
  "Ukrainian",
  "Urdu",
  "Uzbek",
  "Vietnamese",
  "Welsh",
  "Western Frisian",
  "Xhosa",
  "Yiddish",
  "Yoruba",
  "Zulu",
];



export default EditUserContent;
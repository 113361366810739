import React, {useState} from 'react'
import { Container, Form, FormGroup, FormText, Row, Col, Label, Input, Button } from 'reactstrap'
import message from './message'
import Message from './message'



const AddUserContent = ({addUser}) => {
  const [message, setMessage] = useState({color: "success", message: ""})
  const [profilePicture, setProfilePicture] = useState("")
  const [governmentId, setGovernmentId] = useState("")
  const [formData, setFormData] = useState({firstName: "", lastName: "", email: "", gender: "Male",
  marriageStatus: "Unmarried", dateOfBirth: "", passportNumber: "", phoneCode: "", phoneNumber: "",
  ecFirstName: "", ecLastName: "", ecEmail: "", description: "", languages: [], displayLocation: "",
  password: ""
  })

  const submitForm = async(e) => {
    try{
      e.preventDefault();
      const {firstName, lastName, email, phoneNumber, phoneCode, ecFirstName, ecLastName, ecEmail, gender, description, languages, displayLocation, dateOfBirth, passportNumber, marriageStatus, password} = formData
      const emergencyContact = {
        firstName: ecFirstName,
        lastName: ecLastName,
        email: ecEmail
      }

      const formattedDOB = new Date(dateOfBirth)

      // Add the actual request here
      const response = await addUser({variables: {firstName, lastName, email, phoneNumber, phoneCode, gender, description, languages, displayLocation, passportNumber, password, marriageStatus, dateOfBirth: formattedDOB, emergencyContact}})
      console.log(response)


      setMessage({color: "success", message: "Successfully added the user"})
      window.scrollTo(0,0)
    }catch(err) {
      setMessage({color: "danger", message: err.graphQLErrors[0].message})
      window.scrollTo(0,0)
    }
  }

  const changeFormData = async(e) => {
    try{
      let newFormData = Object.assign({}, formData)
      newFormData[e.target.name] = e.target.value
      setFormData(newFormData)
    }catch(err) {
      console.log(err)
    }
  }

  const addLanguage = async() => {
    try{  
      let newFormData = Object.assign({},formData);
      newFormData.languages.push("");
      console.log(newFormData.languages)
      setFormData(newFormData)
    
    }catch(err) {
      console.log(err)
    }
  }

  const languageChange = async(e, index) => {
    try{
      let newFormData = Object.assign({}, formData)
      newFormData.languages[index] = e.target.value
      setFormData(newFormData)
    }catch(err) {
      console.log(err)
    }
  }

  const removeLanguages = async(index) => {
    try{  
      let newFormData = Object.assign({}, formData)
      newFormData.languages = newFormData.languages.filter((lang, i) => {
        return i !== index
      })
      setFormData(newFormData)
    }catch(err) {
      console.log(err)
    }
  }

  return(
    <Container>
      <Message color={message.color} message={message.message} />
      <h1 className="text-center mt-4">Add User</h1>
      <Form className="mt-4 mb-5" onSubmit={submitForm}>
        <FormGroup>
          <Label for="firstName" >First Name</Label>
          <Input type="text" name="firstName" id="firstName" placeholder="First Name" value={formData.firstName} onChange={changeFormData} />
        </FormGroup>
        <FormGroup>
          <Label for="lastName" >Last Name</Label>
          <Input type="text" name="lastName" id="lastName" placeholder="Last Name" value={formData.lastName} onChange={changeFormData} />
        </FormGroup>
        <FormGroup>
          <Label for="password" >Password</Label>
          <Input type="password" name="password" id="password" placeholder="Password" value={formData.password} onChange={changeFormData} />
        </FormGroup>
        <FormGroup>
          <Label for="gender">Gender</Label>
          <Input type="select" name="gender" id="gender" onChange={changeFormData} value={formData.gender}>
            <option>Male</option>
            <option>Female</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="marriageStaus">Marriage Status</Label>
          <Input type="select" name="marriageStatus" id="marrigeStaus" onChange={changeFormData} value={formData.marriageStatus}>
            <option>Married</option>
            <option>Unmarried</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input type="email" name="email" id="email" placeholder="Email" value={formData.email} onChange={changeFormData} />
        </FormGroup>
        <FormGroup>
          <Label for="dob" >Date Of Birth</Label>
          <Input type="date" name="dateOfBirth" id="dob" placeholder="Date Of Birth" value={formData.dateOfBirth} onChange={changeFormData} />
        </FormGroup>
        <FormGroup>
          <Label for="passportNumber" >Passport Number</Label>
          <Input type="text" name="passportNumber" id="passportNumber" placeholder="Passport Number" value={formData.passportNumber} onChange={changeFormData} />
        </FormGroup>
        {/* Will Put the file input in last */}
        <Row>
          <Col md="4">
            <FormGroup>
              <Label for="phoneCode" >Phone Code</Label>
              <Input type="text" name="phoneCode" id="phoneCode" placeholder="PhoneCode" value={formData.phoneCode} onChange={changeFormData} />
            </FormGroup>
          </Col>
          <Col md="8">
            <FormGroup>
              <Label for="phoneNumber" >Phone Number</Label>
              <Input type="text" name="phoneNumber" id="phoneNumber" placeholder="Phone Number" value={formData.phoneNumber} onChange={changeFormData} />
            </FormGroup>
          </Col>
        </Row>
        <h2 className="text-center mt-4">Emergency Contact</h2>
        <FormGroup>
          <Label for="ecFirstName" >First Name</Label>
          <Input type="text" name="ecFirstName" id="ecFirstName" placeholder="First Name" value={formData.ecFirstName} onChange={changeFormData} />
        </FormGroup>
        <FormGroup>
          <Label for="ecLastName" >Last Name</Label>
          <Input type="text" name="ecLastName" id="ecLastName" placeholder="Last Name" value={formData.ecLastName} onChange={changeFormData} />
        </FormGroup>
        <FormGroup>
          <Label for="ecEmail" >Email</Label>
          <Input type="email" name="ecEmail" id="ecEmail" placeholder="Email" value={formData.ecEmail} onChange={changeFormData} />
        </FormGroup>
        <h2 className="text-center">Profile Information</h2>
        <FormGroup>
          <Label for="description" >About Me</Label>
          <Input type="textarea" name="description" id="description" placeholder="About Me" value={formData.description} onChange={changeFormData} />
        </FormGroup>
        {/* If there is no data add the first one */}
        {formData.languages.length == 0 ? 
          <FormGroup>
            <Label for="languages">Languages I can Speak</Label>
            <Input type="select" name="languages" id="languages" value={formData.languages[0]} onChange={(e) => languageChange(e, 0)}>
              {languages.map(lang => {
                return (
                  <option>{lang}</option>
                )
              })}
            </Input>
          </FormGroup> : null}
        {formData.languages.map((lan, i) => {
          return (
            <FormGroup>
              <Label for="languages">Languages I can Speak</Label>
              <Row>
               <Col md="10">
                <Input type="select" name="languages" id="languages" value={lan} onChange={(e) => languageChange(e, i)}>
                  {languages.map(lang => {
                    return (
                      <option>{lang}</option>
                    )
                  })}
                </Input>
               </Col>
               <Col md="2">
                  <Button color="danger" onClick={() => removeLanguages(i)}>Delete</Button>
               </Col>
              </Row>
            </FormGroup>
          )
        })}
        <Button color="primary" className="euc-add-btn" onClick={addLanguage}>+</Button>
        <FormGroup>
          <Label for="displayLocation" >Locations I Am From</Label>
          <Input type="text" name="displayLocation" id="displayLocation" placeholder="Location" value={formData.displayLocation} onChange={changeFormData} />
        </FormGroup>
        <Button color="primary">Add</Button>
      </Form>
    </Container>
  )
}

const languages = [
  "Afrikaans",
  "Albanian",
  "Amharic",
  "Arabic",
  "Armenian",
  "Azerbaijani",
  "Bangla",
  "Basque",
  "Belarusian",
  "Bosnian",
  "Bulgarian",
  "Burmese",
  "Catalan",
  "Cebuano",
  "Chinese (Simplified)",
  "Chinese (Traditional)",
  "Corsican",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "English",
  "Esperanto",
  "Estonian",
  "Filipino",
  "Finnish",
  "French",
  "Galician",
  "Georgian",
  "German",
  "Greek",
  "Gujarati",
  "Haitian Creole",
  "Hausa",
  "Hawaiian",
  "Hebrew",
  "Hindi",
  "Hmong",
  "Hungarian",
  "Icelandic",
  "Igbo",
  "Indonesian",
  "Irish",
  "Italian",
  "Japanese",
  "Javanese",
  "Kannada",
  "Kazakh",
  "Khmer",
  "Korean",
  "Kurdish",
  "Kyrgyz",
  "Lao",
  "Latin",
  "Latvian",
  "Lithuanian",
  "Luxembourgish",
  "Macedonian",
  "Malagasy",
  "Malay",
  "Malayalam",
  "Maltese",
  "Maori",
  "Marathi",
  "Mongolian",
  "Nepali",
  "Norwegian",
  "Nyanja",
  "Pashto",
  "Persian",
  "Polish",
  "Portuguese",
  "Punjabi",
  "Romanian",
  "Russian",
  "Samoan",
  "Scottish Gaelic",
  "Serbian",
  "Shona",
  "Sindhi",
  "Sinhala",
  "Slovak",
  "Slovenian",
  "Somali",
  "Southern Sotho",
  "Spanish",
  "Sundanese",
  "Swahili",
  "Swedish",
  "Tajik",
  "Tamil",
  "Telugu",
  "Thai",
  "Turkish",
  "Ukrainian",
  "Urdu",
  "Uzbek",
  "Vietnamese",
  "Welsh",
  "Western Frisian",
  "Xhosa",
  "Yiddish",
  "Yoruba",
  "Zulu",
];


export default AddUserContent
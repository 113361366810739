import React, { useState } from 'react';
import { Container, Form, FormGroup, Label, Input, Button, Modal, ModalBody, Row, Col } from 'reactstrap';
import { gql } from 'apollo-boost';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import './css/userSearch.css';
import { Redirect } from 'react-router-dom';
import Loading from './loading';
import Message from './message'
import UserCard from './userCard'


const UserSearch = () => {
  const [search, setSearch] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [path, setPath] = useState('');
  const [searchQuery, { loading, errors, data }] = useLazyQuery(
    USER_SEARCH_QUERY
  );
  const [modal, setModal] = useState(false)
  const [deleteUser, deletedUser] = useMutation(DELETE_USER)
  const [userId, setUserId] = useState("")
  const [message, setMessage] = useState({color: "success", message: ""})

  let cards = [];

  if (loading) return <Loading />;

  

  const openModal = async(id) => {
    try{
      setUserId(id)
      setModal(true)
    }catch(err) {
      console.log(err)
    }
  }

  const toggle = async() => {
    try{
      setModal(!modal)
    }catch(err) {
      console.log(err)
    }
  }

  const deletedUserRequest = async() => {
    try{
      await deleteUser({variables: {userId}})
      setMessage({color: "success", message: "Deleted the user succcesfully"})
      setModal(false)
    }catch(err) {
      setMessage({color: "danger", message: err.graphQLErrors[0].message})
      window.scrollTo(0,0)
    }
  }

  if (data) {
    // When the data comes in turn them into cards
    cards = data.adminSearchEmail.map((user) => {
      return (
        <UserCard user={user} openModal={openModal} setPath={setPath} setRedirect={setRedirect} />
      );
    });
  }


  // Deals with the redirects
  if (redirect) {
    return <Redirect to={path} />;
  }
  return (
    <Container>
      <Message message={message.message} color={message.color}/>
      <h2 className="text-center">Search for a user</h2>
      {errors ? <div>Something is wrong</div> : null}
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          searchQuery({ variables: { email: search } });
        }}
      >
        <FormGroup>
          <Label for="search">Search:</Label>
          <Input
            type="text"
            id="search"
            name="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </FormGroup>
        <Button color="primary">Search</Button>
      </Form>
      {cards} 
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <h2 className="text-center">Are you sure you want to delete this user</h2>
          <Row>
            <Col md="10">
              <Button color="danger" onClick={deletedUserRequest}>Yes</Button>
            </Col>
            <Col md="2">
              <Button color="primary" onClick={toggle}>No</Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Container>
  );
};

const USER_SEARCH_QUERY = gql`
  query adminSearchEmail($email: String!) {
    adminSearchEmail(email: $email) {
      firstName
      lastName
      email
      phoneNumber
      id
      phoneCode
    }
  }
`;

const DELETE_USER = gql`
  mutation adminDeleteUser($userId: String!) {
    adminDeleteUser(userId: $userId) {
      id
    }
  }
`


export default UserSearch;

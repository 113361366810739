import React, { useEffect, useState } from 'react'
import Navigation from './Navigation'
import { useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import {Container, FormGroup, Input, Label, Form, Button} from 'reactstrap'
import "./css/notifications.css"
import Message from "../components/message"


const Notificications = () => {

  const [getAllUserQuery, allUserData] = useLazyQuery(ADMIN_ALL_USERS_RECENT) 
  const [getAllHostQuery, allHostData] = useLazyQuery(ADMIN_GET_ALL_HOST)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [notificationMessage, setNotificationMessage] = useState("")
  const [notificationQuery, notificationData] = useLazyQuery(ADMIN_SEND_NOTIFICATION)
  const [selectedAll, setSelectedAll] = useState(false)
  const [selectAllHost, setSelectedAllHost] = useState(false)
  const [selectNoName, setSelectNoName] = useState(false)
  const [notificationTitle, setNotificationTitle] = useState("")
  const [message, setMessage] = useState({color: "success", message: ""})
  const [messageSet, setMessageSet] = useState(false)
  const [madeRequest, setMadeRequest] = useState(false)



  

  useEffect(() => {
    getAllUserQuery()
    getAllHostQuery()
  }, [getAllUserQuery, getAllHostQuery])

  const selectUser = async(e, user) => {
    try{
      // Check if the user is in the selected array
      let index = selectedUsers.findIndex(i => i.id === user.id)
      console.log(index)

      if(index === -1) {
        setSelectedUsers([...selectedUsers, user])
      } else {
        setSelectedUsers([...selectedUsers.filter((use, u) => u !== index)])
      }
    }catch(err) {
      console.log(err)
    }
  }

  const changeMessage = async(e) => {
    try{
      setNotificationMessage(e.target.value)
    }catch(err) {
      console.log(err)
    }
  }

  const changeMessageTitle = async(e) => {
    try {
      setNotificationTitle(e.target.value)
    }catch(err) {
      console.log(err)
    }
  }

  const sendNotifications = async(e) => {
    try{  
      e.preventDefault()
      notificationQuery({variables: {users: selectedUsers.map(user => user.id), title: notificationTitle, body: notificationMessage}})
      setMessageSet(false)
      setNotificationTitle("")
      setNotificationMessage("")
      setMadeRequest(true)
    }catch(err) {
      console.log(err)
    }
  }

  const selectAll = async() => {
    try{
      clearFilters("all")
      if(selectedAll) {
        setSelectedUsers([])

      } else {
        if(allUserData.data !== undefined) {
          setSelectedUsers([...allUserData.data.adminAllUsersRecent.map(x => x)])
        }
      }
      setSelectedAll(!selectedAll)
    }catch(err) {
      console.log(err)
    }
  }

  const selectAllHostChange = async() => {
    try {
      clearFilters("host")
      if(selectAllHost) {
        setSelectedUsers([])
      } else {
        if (allHostData.data !== undefined) {
          setSelectedUsers([...allHostData.data.adminGetAllHost.map(x => x)])
        }
      } 

      setSelectedAllHost(!selectAllHost)
    }catch(err) {
      console.log(err)
    }
  }

  const selectNoNameChange = async() => {
    try{

      clearFilters("noName")

      if(selectNoName) {
        setSelectedUsers([])
      } else {
        if(allUserData.data !== undefined) {
          setSelectedUsers([...allUserData.data.adminAllUsersRecent.filter(x => x.firstName === null || x.lastName === null)])
        }
      }
      setSelectNoName(!selectNoName)
    }catch(err) {
      console.log(err)
    }
  }

  const clearFilters = async(currentFilter ) => {
    try { 
      if(currentFilter === "all") {
        setSelectNoName(false)
        setSelectedAllHost(false)
      }

      if(currentFilter === 'noName') {
        setSelectedAllHost(false)
        setSelectedAll(false)
      }

      if(currentFilter === "host") {
        setSelectedAll(false)
        setSelectNoName(false)
      }
      
    }catch(err) {
      console.log(err)
    }
  }

  if(!messageSet && !notificationData.loading && madeRequest) {
    setMessageSet(true)
    if(notificationData.data !== undefined) {
      console.log("Got here")
      setMessage({color: "success", message: "Successfully send notifications"})
    }
    else {
      setMessage({color: "danger", message: "Something went wrong"})

    }
  }

  return(
    <div>
      <Navigation />
      <Message color={message.color} message={message.message} />
      <h1 className='text-center'>Notification page</h1>
      <Container>
        <div className='notification-filter-container'>
          <div>
            <FormGroup>
              <Input type='checkbox' name='selectAllUsers' onChange={selectAll} checked={selectedAll} />
              <Label>Select All</Label>
            </FormGroup>
          </div>
          <div>
            <FormGroup>
              <Input type='checkbox' name='selectAllHost' onChange={selectAllHostChange} checked={selectAllHost} />
              <Label>All Host</Label>
            </FormGroup>
          </div>
          <div>
            <FormGroup>
              <Input type='checkbox' name='selectNpNames' onChange={selectNoNameChange} checked={selectNoName} />
              <Label>No Names</Label>
            </FormGroup>
          </div>
        </div>
        <div className='notification-user-conatiner mt-5'>
          {allUserData.data !== undefined ? allUserData.data.adminAllUsersRecent.map(user => {
            let index = selectedUsers.findIndex(use => use.id == user.id)
            return (
              <div>
                <FormGroup check>
                  <Input
                    onChange={(e) => {
                      selectUser(e, user)
                    }}
                    checked={index !== -1 ? true : false}
                    id="checkboxUser"
                    type="checkbox"
                  />
                  {' '}
                  <Label check>
                    {user.firstName + " " + user.lastName}
                  </Label>
                </FormGroup>
              </div>
            )
          }) : null}
        </div>
        <div className='mt-5'>
          <Form onSubmit={sendNotifications}>
            <FormGroup>
              <Label>Message Title:</Label>
              <Input type="text" name="notificationMessage" onChange={changeMessageTitle} value={notificationTitle} />
            </FormGroup>
            <FormGroup>
              <Label>Message to send:</Label>
              <Input type="text" name="notificationMessage" onChange={changeMessage} value={notificationMessage} />
            </FormGroup>
            <Button color="primary">Send Message</Button>
          </Form>
        </div>
      </Container>
    </div>
  )
}

const ADMIN_ALL_USERS_RECENT = gql`
  query adminAllUsersRecent {
    adminAllUsersRecent {
      id
      firstName
      lastName
      email
      phoneCode
      phoneNumber
    }
  }
`

const ADMIN_SEND_NOTIFICATION = gql`
  query adminSendNotifications($users: [ID!], $title: String!, $body: String!) {
    adminSendNotifications(users: $users, title: $title, body: $body) {
      id
      firstName
      lastName
      email
      phoneNumber
      phoneCode
    }
  }
`

const ADMIN_GET_ALL_HOST = gql`
  query adminGetAllHost {
    adminGetAllHost {
      id
      firstName
      lastName
      email
      phoneCode
      phoneNumber
    }
  }
`

export default Notificications
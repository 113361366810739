import React, {useState, useEffect} from 'react'
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Navigation from './Navigation'
import UserCard from "../components/userCard"
import { Container, Form, FormGroup, Label, Input, Button, Modal, ModalBody, Row, Col } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import Message from '../components/message'


const AllUsers = () => {

    const [usersQuery, users] = useLazyQuery(ADMIN_ALL_USER_RECENT_QUERY)
    const [modal, setModal] = useState(false)
    const [redirect, setRedirect] = useState(false);
    const [path, setPath] = useState('');
    const [deleteUser, deletedUser] = useMutation(DELETE_USER)
    const [userId, setUserId] = useState("")
    const [message, setMessage] = useState({color: "success", message: ""})

    useEffect(() => {
        usersQuery()
    }, [])

    const toggle = async() => {
        try{
            setModal(!modal)
        }catch(err) {
            console.log(err)
        }
    }

    const openModal = async(id) => {
        try{
          setUserId(id)
          setModal(true)
        }catch(err) {
          console.log(err)
        }
      }

    const deletedUserRequest = async() => {
        try{
            await deleteUser({variables: {userId}})
            setMessage({color: "success", message: "Deleted the user succcesfully"})
            setModal(false)
        }catch(err) {
            setMessage({color: "danger", message: err.graphQLErrors[0].message})
            window.scrollTo(0,0)
        }
    }


    if (redirect) {
        return <Redirect to={path} />;
    }
    return (
        <div>
            <Navigation />
            <Container>
                <Message message={message.message} color={message.color}/>
                <h1 className='text-center mt-5'>All Users</h1>
                {
                    users.data !== undefined ?  users.data.adminAllUsersRecent.map(user => {
                        return (
                            <UserCard user={user} openModal={openModal} setPath={setPath} setRedirect={setRedirect} />
                        )
                    }) : null
                }
                <Modal isOpen={modal} toggle={toggle}>
                    <ModalBody>
                        <h2 className="text-center">Are you sure you want to delete this user</h2>
                        <Row>
                            <Col md="10">
                                <Button color="danger" onClick={deletedUserRequest}>Yes</Button>
                            </Col>
                            <Col md="2">
                                <Button color="primary" onClick={toggle}>No</Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </Container>
        </div>
        
    )
}

const ADMIN_ALL_USER_RECENT_QUERY = gql`
    query adminAllUsersRecent {
        adminAllUsersRecent {
            id
            email
            phoneNumber
            phoneCode
            firstName
            lastName
        }
    }
`;

const DELETE_USER = gql`
  mutation adminDeleteUser($userId: String!) {
    adminDeleteUser(userId: $userId) {
      id
    }
  }
`;

export default AllUsers
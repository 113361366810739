import React, { useEffect, useState } from 'react';
import Navigation from './Navigation';
import { useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Container, Row, Col } from 'reactstrap';
import './css/userVerificationList.css';
import Loading from '../components/loading';
import { Redirect } from 'react-router-dom';

const HostVerificationList = () => {
  const [hostVerificationQuery, hostVerification] = useLazyQuery(
    ADMIN_HOST_AWAITING_VERIFICATION
  );
  const [hosts, setHosts] = useState([]);
  const [dataSet, setDataSet] = useState(false);
  const [path, setPath] = useState('');

  useEffect(() => {
    hostVerificationQuery();
    console.log(dataSet);
    // return () => {
    //   setDataSet(false);
    // };
  }, []);

  if (hostVerification.loading) return <Loading />;
  if (hostVerification.error) return <div>{hostVerification.error}</div>;
  if (hostVerification.data && !dataSet) {
    console.log('Got here');
    setHosts(hostVerification.data.adminHostAwaitingVerification);
    setDataSet(true);
  }

  if (path != '') {
    return <Redirect to={path} />;
  }

  return (
    <div>
      <Navigation />
      <Container className="text-center">
        <h2 className="mt-5">Host verification</h2>
        {hosts.map((user) => {
          return (
            <div
              key={user.id}
              className="userButton"
              onClick={() => {
                setPath(`/user/${user.id}`);
              }}
            >
              <p>
                {user.firstName} {user.lastName}
              </p>
            </div>
          );
        })}
      </Container>
    </div>
  );
};

const ADMIN_HOST_AWAITING_VERIFICATION = gql`
  {
    adminHostAwaitingVerification {
      id
      firstName
      lastName
    }
  }
`;

export default HostVerificationList;

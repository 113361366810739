import React from "react"
import Navigation from './Navigation'
import AddUserContent from '../components/addUserContent'
import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'

const AddUser = (props) => {
	const [addUser, user] = useMutation(ADMIN_MAKE_USER)


	return (
			<div>
				<Navigation />
				<AddUserContent addUser={addUser} />
			</div>
	)
}

const ADMIN_MAKE_USER = gql`
	mutation adminMakeUser($firstName: String!, $lastName: String!, $email: String!, 
		$gender: String!, $marriageStatus: String!, $dateOfBirth: String!, $passportNumber: String!,
		$phoneCode: String!, $phoneNumber: String!, $emergencyContact: emergencyContactInput!,
		$description: String!, $languages: [String!], $displayLocation: String!, $password: String!) {
			adminMakeUser(firstName: $firstName, lastName: $lastName, email: $email, gender: $gender,
				marriageStatus: $marriageStatus, dateOfBirth: $dateOfBirth, passportNumber: $passportNumber,
				phoneCode: $phoneCode, phoneNumber: $phoneNumber, emergencyContact: $emergencyContact, 
				description: $description, languages: $languages, displayLocation: $displayLocation, password: $password
				) {
					id
				}
		}
`

export default AddUser;
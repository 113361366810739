import React from 'react'
import {  Alert } from 'reactstrap'

const message = (props) => {
  return (
    props.message === "" ? null :
    <Alert color={props.color}>
      {props.message}
    </Alert>
  )
}

export default message
import React, { useEffect, useState } from "react"
import Navigation from './Navigation'
import { Container, Form, FormGroup, Label, Input, Button, Modal, ModalBody, Row, Col } from 'reactstrap';
import UserCard from '../components/userCard'
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Redirect } from 'react-router-dom';
import Message from '../components/message'



const AllHosts = () => {

    const [hostsQuery, hosts] = useLazyQuery(ADMIN_GET_ALL_HOST_QUERY)
    const [modal, setModal] = useState(false)
    const [redirect, setRedirect] = useState(false);
    const [path, setPath] = useState('');
    const [deleteUser, deletedUser] = useMutation(DELETE_USER)
    const [userId, setUserId] = useState("")
    const [message, setMessage] = useState({color: "success", message: ""})


    useEffect(() => {
        hostsQuery()
    }, [])

    const toggle = async () => {
        try{
            setModal(!modal)
        }catch(err) {
            console.log(err)
        }
    }

    const deletedUserRequest = async() => {
        try{
            await deleteUser({variables: {userId}})
            setMessage({color: "success", message: "Deleted the user succcesfully"})
            setModal(false)
        }catch(err) {
            setMessage({color: "danger", message: err.graphQLErrors[0].message})
            window.scrollTo(0,0)
        }
    }

    const openModal = async(id) => {
        try{
          setUserId(id)
          setModal(true)
        }catch(err) {
          console.log(err)
        }
      }

    if (redirect) {
        return <Redirect to={path} />;
    }
    return (
        <div>
            <Navigation />
            <Container className="text-center mt-5">
                <Message message={message.message} color={message.color}/>
                <h1>All Host</h1>
                <h4>Everyone that has started a listing</h4>
                {hosts.data !== undefined ? 
                    hosts.data.adminGetAllHost.map(host => {
                        return (
                            <div>
                                <UserCard user={host} openModal={openModal} setPath={setPath} setRedirect={setRedirect} />
                            </div>
                        )
                    }) : null}
                <Modal isOpen={modal} toggle={toggle}>
                    <ModalBody>
                        <h2 className="text-center">Are you sure you want to delete this user</h2>
                        <Row>
                            <Col md="10">
                                <Button color="danger" onClick={deletedUserRequest}>Yes</Button>
                            </Col>
                            <Col md="2">
                                <Button color="primary" onClick={toggle}>No</Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </Container>
        </div>
    )
}

const ADMIN_GET_ALL_HOST_QUERY = gql`
    query adminGetAllHost {
        adminGetAllHost {
            id
            email
            phoneNumber
            phoneCode
            firstName
            lastName
        }
    }


`;

const DELETE_USER = gql`
  mutation adminDeleteUser($userId: String!) {
    adminDeleteUser(userId: $userId) {
      id
    }
  }
`;

export default AllHosts
import React from 'react';
import { Spinner } from 'reactstrap';
import './css/loading.css';

const Loading = () => {
  return (
    <div className="loadingContainer">
      <Spinner color="primary"></Spinner>
    </div>
  );
};

export default Loading;

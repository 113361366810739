import React, { Component, useState } from 'react';
import { Form, FormGroup, Label, Input, FormText, Button } from 'reactstrap';
import './css/loginFields.css';
import { useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Redirect } from 'react-router-dom';

const ADMIN_LOGIN = gql`
  query adminLogin($email: String!, $password: String!) {
    adminLogin(email: $email, password: $password) {
      jwt
    }
  }
`;

const LoginFields = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [login, { loading, error, data }] = useLazyQuery(ADMIN_LOGIN);

  if (loading) return <p>Loading ...</p>;

  if (data && data.adminLogin) {
    localStorage.setItem('jwt', data.adminLogin.jwt);
    props.reloadApp()
  }

  return (
    <div className="loginContainer">
      <h2 className="text-center">Admin Login</h2>
      {error ? (
        <div style={{ color: 'red' }}>{error.graphQLErrors[0].message}</div>
      ) : null}
      <Form onSubmit={() => login({ variables: { email, password } })}>
        <FormGroup>
          <Label for="email">Email:</Label>
          <Input
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          ></Input>
        </FormGroup>
        <FormGroup>
          <Label for="password">Password:</Label>
          <Input
            name="password"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
        </FormGroup>
        <Button color="primary">Login</Button>
      </Form>
    </div>
  );
};

export default LoginFields;

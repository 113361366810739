import React, { useState } from 'react'
import { Form, FormGroup, Label, Input, FormText, Row, Container, Col, Button } from 'reactstrap'
import currencyList from "./currency"
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { Redirect } from 'react-router-dom';

import Message from './message'
import Loading from './loading';
const setTime = (time) => {
    try{
        if(time.split(" ")[1] == "AM") {
            return time.split(" ")[0]
        }
        else {
            let firstSplit = time.split(" ")[0]
            let hourSplit = parseInt(firstSplit.split(":")[0])
            hourSplit += 12
            return `${hourSplit}:00`
        }
    }catch(err) {
        console.log(err)
    }
}

const EditListingForm = ({listing, editListing, listingId}) => {

    const [message, setMessage] = useState({message: "", color: "success"});
    const [formData, setFormData] = useState({type: listing.type[0], guest: listing.guest, country: listing.location.country, streetAddress: listing.location.streetAddress, apartmentSuite: listing.location.apartmentSuite, city: listing.location.city, state: listing.location.state, postCode: listing.location.postCode, longitude: listing.location.longitude, latitude: listing.location.latitude, TV: listing.TV, coffee: listing.coffee, cooking: listing.cooking, hotTub: listing.hotTub, kitchen: listing.kitchen, landryDryer: listing.landryDryer, landryWashing: listing.landryWashing, pool: listing.pool, privateLounge: listing.privateLounge, shower: listing.shower, wifi: listing.wifi, place: listing.place, title: listing.title, children: listing.children == "tick" ? true : false, infants: listing.infants == "tick" ? true : false , smoking: listing.smoking == "none" ? false : true, alcohol: listing.alcohol == "none" ? false : true, haveChildren: false, havePets: false, noParking: true, sharedSpace: false, additionalRules: listing.additionalRules, preferedGender: listing.preferedGender, preferedMaritial: listing.preferedMaritial, price: listing.price, currency: listing.currency, upToDateCalender: listing.upToDateCalender, checkInFrom: setTime(listing.checkInFrom), checkInTo: setTime(listing.checkInTo), minNights: listing.minNights, maxNights: listing.maxNights})
    const [adminEditListing, adminListing] = useMutation(ADMIN_EDIT_LISTING)
    const [sleepingArrangement, setSleepingArrangement] = useState(listing.sleepingArrangement)
    const [published, setPublishedState] = useState(listing.published)
    const [redirect, setRedirect] = useState(false)
    const [path, setPath] = useState("")
    
    const changeFormData = async(e) => {
        try{
          let newFormData = Object.assign({}, formData)
          newFormData[e.target.name] = e.target.value
          setFormData(newFormData)
        }catch(err) {
          console.log(err)
        }
      }

    const changeCheckBox = async(e) => {
        try{
            let newFormData = Object.assign({}, formData)
            newFormData[e.target.name] = !newFormData[e.target.name]
            setFormData(newFormData)
        }catch(err) {
            console.log(err)
        }
    }
    
    const changeSleepingArragement = async (index, field, newAmount) => {
        try{
            let arragements = sleepingArrangement.map(sleep => sleep);
            arragements[index][field] = parseInt(newAmount)
            setSleepingArrangement(arragements)
        }catch(err) {
            console.log(err)
        }
    }

    const addSleepingArragement = async() => {
        try{
            let arragements = sleepingArrangement.map(sleep => sleep)
            const baseSleepingArrangment = {
                single: 0,
                double: 0,
                queen: 0,
                sofaBed: 0,
                floorMattress: 0,
                airMattress: 0,
                toddlerBed: 0
            }
            let newSleeping = Object.assign({}, baseSleepingArrangment)
            arragements.push(newSleeping)
            setSleepingArrangement(arragements)
        }catch(err) {
            console.log(err)
        }
    }

    const removeSleepingArragement = async(index) => {
        try{
           let arragements = sleepingArrangement.map(sleep => sleep) 
           arragements = arragements.filter((arr, i) => i !== index)
           setSleepingArrangement(arragements)
        }catch(err) {
            console.log(err)
        }
    }

    const setTimeSend = (time) => {
        try{
            if(parseInt(time.split(":")[0]) <= 12 ) {
                return time + " AM"
            }
            

            return time + " PM"
        }catch(err) {
            console.log(err)
        }
    }

    const onSubmit = async(e) => {
        try{
            e.preventDefault()
            let arragements = sleepingArrangement.map(sleep => {
                delete sleep.__typename
                return sleep
            })

            

            const {guest, type,  country, streetAddress, apartmentSuite, city, state, postCode, longitude, latitude, TV, coffee, cooking, hotTub, kitchen, landryDryer, landryWashing, pool, privateLounge, shower, wifi, place, title, children, infants, smoking, alcohol, haveChildren, havePets, noParking, sharedSpace, additionalRules, preferedGender, preferedMaritial, price, currency, upToDateCalender, checkInFrom, checkInTo, minNights, maxNights} = formData
            let listing = {
                type: [type],
                guest: parseInt(guest),
                sleepingArrangement: arragements,
                location: {
                    country,
                    streetAddress,
                    apartmentSuite,
                    city,
                    state,
                    postCode,
                    longitude: parseFloat(longitude),
                    latitude: parseFloat(latitude),
                },
                TV,
                coffee,
                cooking,
                hotTub,
                kitchen,
                landryDryer,
                landryWashing,
                pool,
                privateLounge, 
                shower,
                wifi,
                place,
                title,
                children: children ? "tick": "cross",
                infants: infants ? "tick": "cross",
                smoking: smoking ? "tick" : "cross",
                alcohol: alcohol ? "tick": "cross",
                haveChildren,
                havePets,
                noParking,
                sharedSpace,
                preferedGender,
                preferedMaritial,
                additionalRules,
                price: parseFloat(price),
                currency,
                upToDateCalender,
                checkInFrom: setTimeSend(checkInFrom),
                checkInTo: setTimeSend(checkInTo),
                minNights: parseInt(minNights),
                maxNights: parseInt(maxNights)
            }

            let editListingReq = await editListing({variables: {listing, listingId}}, {errorPolicy: "all"})
            if(editListingReq.data != null && editListingReq.data.adminEditListing != null) {
                setMessage({message: "Succefully updated the listing", color: "success"})
            }
            else {
                setMessage({message: "Something went wrong please try again later", color: "error"})
            }

            window.scrollTo(0,0)

        }catch(err) {
            console.log(err)
        }
    }

    const setPublished = async (publishedStatus) => {
        try{
            let isPublished = false
            if(publishedStatus == 0) {
                isPublished = true
                setPublishedState(true)
            }
            else {
                setPublishedState(false)
            }
            let newEditListing  = await editListing({variables: {listingId, listing: {published: isPublished}}})
            if(newEditListing.data != null && newEditListing.data.adminEditListing != null) {
                setMessage({message: "Sucessfully published the listing", color: "success"})
            } else {
                setMessage({message: "Something went wrong, please try again later", color: "error"})
            }

            window.scrollTo(0, 0)
        }catch(err) {
            console.log(err)
        }
    }

    if(adminListing.loading) {
        return <Loading />
    }

    if (redirect) {
        return <Redirect to={path} />;
    }
    return (
        <Container>
            <Message color={message.color} message={message.message} />
            <h1 className="text-center mt-4">Edit Lisiting</h1>
            <Form className="mt-4 mb-5" onSubmit={onSubmit} >
                <FormGroup>
                    <Label for="type" >Type</Label>
                    <Input type="select" name="type" id="type" placeholder="Type" value={formData.type} onChange={changeFormData}>
                        <option>Private Room</option>
                        <option>Shared Room</option>
                        <option>Couch</option>
                    </Input>
                </FormGroup>

                <FormGroup>
                    <Label for="guest" >Guest</Label>
                    <Input type="number" name="guest" id="guest" placeholder="Guest" value={formData.guest} onChange={changeFormData} />
                </FormGroup>
                <h2 className="text-center mt-4">Sleeping Arrangements</h2>
                {sleepingArrangement.length == 0 ? <Button color='primary' className="mr-5"  onClick={addSleepingArragement} >Add Room</Button> : null}

                {sleepingArrangement.map((sleep, i) => {
                    return (
                        <div>
                            <h4>Arrangements {i + 1}:</h4>
                            <FormGroup>
                                <Label for="single" >Single Beds</Label>
                                <Input type="number" name="single" id="single" placeholder="Single Beds" value={sleep.single} onChange={(e) => changeSleepingArragement(i, "single", e.target.value)} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="double" >Double Beds</Label>
                                <Input type="number" name="double" id="double" placeholder="Double Beds" value={sleep.double} onChange={(e) => changeSleepingArragement(i, "double", e.target.value)} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="queen" >Queen Beds</Label>
                                <Input type="number" name="queen" id="queen" placeholder="Queen Beds" value={sleep.queen} onChange={(e) => changeSleepingArragement(i, "queen", e.target.value)} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="sofaBed" >Sofa Beds</Label>
                                <Input type="number" name="sofaBed" id="sofaBed" placeholder="Sofa Beds" value={sleep.sofaBed} onChange={(e) => changeSleepingArragement(i, "sofaBed", e.target.value)} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="floorMattress" >Floor Mattress</Label>
                                <Input type="number" name="floorMattress" id="floorMattress" placeholder="Floor Mattress" value={sleep.floorMattress} onChange={(e) => changeSleepingArragement(i, "floorMattress", e.target.value)} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="airMattress" >Air Mattress</Label>
                                <Input type="number" name="airMattress" id="airMattress" placeholder="Air Mattress" value={sleep.airMattress} onChange={(e) => changeSleepingArragement(i, "airMattress", e.target.value)} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="toddlerBed" >Toddler Bed</Label>
                                <Input type="number" name="toddlerBed" id="toddlerBed" placeholder="Toddler Bed" value={sleep.toddlerBed} onChange={(e) => changeSleepingArragement(i, "toddlerBed", e.target.value)} />
                            </FormGroup>
                            {i + 1 == sleepingArrangement.length ? <Button color='primary' className="mr-5"  onClick={addSleepingArragement} >Add Room</Button> : null}
                            {i !== 0 ? <Button color='danger' onClick={() => removeSleepingArragement(i)} >Remove Room</Button> : null}
                        </div>
                    )
                })}

                <h2 className="text-center mt-4">Location</h2>
                <FormGroup>
                    <Label for="country" >Country/Region</Label>
                    <Input type="text" name="country" id="country" placeholder="Country/Region" value={formData.country} onChange={changeFormData} />
                </FormGroup>
                <FormGroup>
                    <Label for="streetAddress" >Street Address</Label>
                    <Input type="text" name="streetAddress" id="streetAddress" placeholder="Street Address" value={formData.streetAddress} onChange={changeFormData} />
                </FormGroup>
                <FormGroup>
                    <Label for="appartmentSuite" >Appartment/Suite</Label>
                    <Input type="text" name="appartmentSuite" id="appartmentSuite" placeholder="Appartment/Suite" value={formData.appartmentSuite} onChange={changeFormData} />
                </FormGroup>
                <FormGroup>
                    <Label for="city" >City</Label>
                    <Input type="text" name="city" id="city" placeholder="City" value={formData.city} onChange={changeFormData} />
                </FormGroup>
                <FormGroup>
                    <Label for="state" >State</Label>
                    <Input type="text" name="state" id="state" placeholder="State" value={formData.state} onChange={changeFormData} />
                </FormGroup>
                <FormGroup>
                    <Label for="postCode" >Post Code</Label>
                    <Input type="text" name="postCode" id="postCode" placeholder="Post Code" value={formData.postCode} onChange={changeFormData} />
                </FormGroup>
                <FormGroup>
                    <Label for="longitude" >Longitude</Label>
                    <Input type="text" name="longitude" id="longitude" placeholder="Longitude" value={formData.longitude} onChange={changeFormData} />
                </FormGroup>
                <FormGroup>
                    <Label for="latitude" >Latitude</Label>
                    <Input type="text" name="latitude" id="latitude" placeholder="latitude" value={formData.latitude} onChange={changeFormData} />
                </FormGroup>
                <h2 className="text-center mt-4">Amenities</h2>
                <FormGroup>
                    <Input type="checkbox" name="wifi" id="wifi" placeholder="Wifi" onChange={changeCheckBox} checked={formData.wifi} />
                    <Label for="wifi" >Wifi</Label>
                </FormGroup>
                <FormGroup>
                    <Input type="checkbox" name="shower" id="shower" placeholder="Shower" checked={formData.shower} onChange={changeCheckBox} />
                    <Label for="shower" >Shower</Label>
                </FormGroup>
                <FormGroup>
                    <Input type="checkbox" name="cooking" id="cooking" placeholder="Cooking Facilities" checked={formData.cooking} onChange={changeCheckBox} />
                    <Label for="cooking" >Cooking Facilities</Label>
                </FormGroup>
                <FormGroup>
                    <Input type="checkbox" name="TV" id="TV" placeholder="TV" checked={formData.TV} onChange={changeCheckBox} />
                    <Label for="TV" >TV</Label>
                </FormGroup>
                <FormGroup>
                    <Input type="checkbox" name="coffee" id="coffee" placeholder="Tea, Coffee.." checked={formData.coffee} onChange={changeCheckBox} />
                    <Label for="coffee" >Tea, Coffee....</Label>
                </FormGroup>
                <FormGroup>
                    <Input type="checkbox" name="kitchen" id="kitchen" placeholder="Kitchen" checked={formData.kitchen} onChange={changeCheckBox} />
                    <Label for="kitchen" >Kitchen</Label>
                </FormGroup>
                <FormGroup>
                    <Input type="checkbox" name="privateLounge" id="privateLounge" placeholder="Private Lounge" checked={formData.privateLounge} onChange={changeCheckBox} />
                    <Label for="privateLounge" >Private Lounge</Label>
                </FormGroup>
                <FormGroup>
                    <Input type="checkbox" name="landryWashing" id="landryWashing" placeholder="Laundry - Washing Machine" checked={formData.landryWashing} onChange={changeCheckBox} />
                    <Label for="landryWashing" >Laundy - Washing Machine</Label>
                </FormGroup>
                <FormGroup>
                    <Input type="checkbox" name="landryDryer" id="landryDryer" placeholder="Laundry - Dryer" checked={formData.landryDryer} onChange={changeCheckBox} />
                    <Label for="landryDryer" >Laundy - Dryer</Label>
                </FormGroup>
                <FormGroup>
                    <Input type="checkbox" name="pool" id="pool" placeholder="Pool" checked={formData.pool} onChange={changeCheckBox} />
                    <Label for="pool" >Pool</Label>
                </FormGroup>
                <FormGroup>
                    <Input type="checkbox" name="hotTub" id="hotTub" placeholder="Hot Tub" checked={formData.hotTub} onChange={changeCheckBox} />
                    <Label for="hotTub" >Hot Tub</Label>
                </FormGroup>
                <h2 className="text-center mt-4">Place Description</h2>
                <FormGroup>
                    <Label for="place" >Description Of Place</Label>
                    <Input type="text" name="place" id="place" placeholder="Description" value={formData.place} onChange={changeFormData} />
                </FormGroup>
                <h2 className="text-center mt-4">Place Name</h2>
                <FormGroup>
                    <Label for="title" >Place Name</Label>
                    <Input type="text" name="title" id="title" placeholder="Place Name" value={formData.title} onChange={changeFormData} />
                </FormGroup>
                <h2 className="text-center mt-4">House Rules</h2>

                <FormGroup>
                    <Input type="checkbox" name="children" id="children" placeholder="Suitable for children (2-12 years)" checked={formData.children} onChange={changeCheckBox} />
                    <Label for="children" >Suitable for children (2-12 years)</Label>
                </FormGroup>
                <FormGroup>
                    <Input type="checkbox" name="infants" id="infants" placeholder="Suitable for infants (Under 2 years)" checked={formData.infants} onChange={changeCheckBox} />
                    <Label for="infants" >Suitable for infants (Under 2 years)</Label>
                </FormGroup>
                <FormGroup>
                    <Input type="checkbox" name="smoking" id="smoking" placeholder="Smoking Allowed" checked={formData.smoking} onChange={changeCheckBox} />
                    <Label for="smoking" >Smoking Allowed</Label>
                </FormGroup>
                <FormGroup>
                    <Input type="checkbox" name="alcohol" id="alcohol" placeholder="Alcohol Allowed" checked={formData.alcohol} onChange={changeCheckBox} />
                    <Label for="alcohol" >Alcohol Allowed</Label>
                </FormGroup>
                <FormGroup>
                    <Input type="checkbox" name="haveChildren" id="haveChildren" placeholder="We have Children" checked={formData.haveChildren} onChange={changeCheckBox} />
                    <Label for="haveChildren" >We have Children</Label>
                </FormGroup>
                <FormGroup>
                    <Input type="checkbox" name="havePets" id="havePets" placeholder="Pet(s) live on property" checked={formData.havePets} onChange={changeCheckBox} />
                    <Label for="havePets" >Pet(s) live on property</Label>
                </FormGroup>
                <FormGroup>
                    <Input type="checkbox" name="noParking" id="noParking" placeholder="No Parking on Property" checked={formData.noParking} onChange={changeCheckBox} />
                    <Label for="noParking" >No Parking on Property</Label>
                </FormGroup>
                <FormGroup>
                    <Input type="checkbox" name="sharedSpace" id="sharedSpace" placeholder="Some spaces are shared" checked={formData.sharedSpace} onChange={changeCheckBox} />
                    <Label for="sharedSpace" >Some spaces are Shared</Label>
                </FormGroup>

                <h2 className="text-center mt-4">Additional Rules</h2>
                <FormGroup>
                    <Label for="additionalRules" >Additional Rules</Label>
                    <Input type="text" name="additionalRules" id="additionalRules" placeholder="Additional Rules" value={formData.additionalRules} onChange={changeFormData} />
                </FormGroup>

                <h2 className="text-center mt-4">Guest Preference</h2>
                <FormGroup>
                    <Label for="preferedGender">Prefered Gender</Label>
                    <Input type="select" name="preferedGender" id="preferedGender" onChange={changeFormData} value={formData.preferedGender}>
                        <option>Doesn't Matter</option>
                        <option>Male</option>
                        <option>Female</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="preferedMaritial">Prefered Maritial Status</Label>
                    <Input type="select" name="preferedMaritial" id="preferedMaritial" onChange={changeFormData} value={formData.preferedMaritial}>
                        <option>Doesn't Matter</option>
                        <option>Married</option>
                    </Input>
                </FormGroup>

                <h2 className="text-center mt-4">Fees</h2>
                <FormGroup>
                    <Label for="price" >Optional Fee</Label>
                    <Input type="number" name="price" id="price" placeholder="Optional Fee" value={formData.price} onChange={changeFormData} />
                </FormGroup>
                <FormGroup>
                    <Label for="currency">Fee Currency</Label>
                    <Input type="select" name="currency" id="currency" onChange={changeFormData} value={formData.currency}>
                        {currencyList.map((curr) => {
                            return (
                                <option>{curr.code}</option>
                            )
                        })}
                    </Input>
                </FormGroup>
                <h2 className="text-center mt-4">Calender</h2>
                <FormGroup>
                    <Input type="checkbox" name="upToDateCalender" id="upToDateCalender" placeholder="Keep Calender Up To Date" checked={formData.upToDateCalender} onChange={changeFormData} />
                    <Label for="upToDateCalender" >Keep Calender Up To Date</Label>
                </FormGroup>
                <FormGroup>
                    <Label for="checkInFrom" >Check In Time</Label>
                    <Input type="time" name="checkInFrom" id="checkInFrom" placeholder="Check In Time" value={formData.checkInFrom} onChange={changeFormData} />
                </FormGroup>
                <FormGroup>
                    <Label for="checkInTo" >Check Out Time</Label>
                    <Input type="time" name="checkInTo" id="checkInTo" placeholder="Check Out Time" value={formData.checkInTo} onChange={changeFormData} />
                </FormGroup>
                
                <FormGroup>
                    <Label for="minNights" >Min Nights</Label>
                    <Input type="number" name="minNights" id="minNights" placeholder="Min Nights" value={formData.minNights} onChange={changeFormData} />
                </FormGroup>
                <FormGroup>
                    <Label for="maxNights" >Max Nights</Label>
                    <Input type="number" name="maxNights" id="maxNights" placeholder="Max Nights" value={formData.maxNights} onChange={changeFormData} />
                </FormGroup>
                <Button color="primary">Edit Listing</Button>
                <h2 className='text-center mt-4' >Published</h2>
                <FormGroup check>
      <Input
        name="published"
        type="radio"
        checked={published}
        onChange={() => setPublished(0)}
      />
      {' '}
      <Label check>
        Yes
      </Label>
    </FormGroup>
    <FormGroup check>
      <Input
        name="published"
        type="radio"
        checked={!published ? true : false}
        onChange={() => setPublished(1)}
      />
      {' '}
      <Label check>
    No
      </Label>
    </FormGroup>
            </Form>
            <Button color='warning'  className="mt-2 mb-5" onClick={() => {
                setPath("/listing/images/" + listingId)
                setRedirect(true)
            }} >Images</Button>
        </Container>
    )
}

const ADMIN_EDIT_LISTING = gql`
    mutation adminEditListing($userId: String!, $listing: Listing!) {
        adminEditListing(userId: $userId, listing: $listing) {
            id
        }
    }
`


export default EditListingForm
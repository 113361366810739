import React, {useState} from 'react'
import { Container, Form,  FormGroup, Input, Label, Button } from 'reactstrap'
import Message from './message'

const ResetPasswordContent = ({resetPassword, userId}) => {
  const [formData, setFormData] = useState({password: "", confirmPassword: ""})
  const [message, setMessage] = useState({color: "primary", message: ""})

  const changeFormData = async(e) => {
    try{
      let newFormData = Object.assign({}, formData)
      newFormData[e.target.name] = e.target.value
      setFormData(newFormData)
    }catch(err) {
      console.log(err)
    }
  }
  

  const submitForm = async(e) => {
    try{
      e.preventDefault()
      if(formData.password === formData.confirmPassword) {
        await resetPassword({variables: {userId, password: formData.password }})
        setMessage({color: "success", message: "Successfully changed the user password"})
      } else {
        window.scrollTo(0,0)
        setMessage({color: "danger", message: "The passwords do not match"})
      }

    }catch(err) {
      console.log(err)
      window.scrollTo(0,0)
      setMessage({color: "danger", message: err.graphQLErrors[0].message})
    }
  }

  return (
    <Container>
      <Message message={message.message} color={message.color} />
      <h1 className="text-center">Reset User's Password</h1>
      <Form onSubmit={submitForm}>
        <FormGroup>
          <Label for="password">New Password</Label>
          <Input type="text" name="password" id="password" placeholder="New Password" value={formData.password} onChange={changeFormData} />
        </FormGroup>
        <FormGroup>
          <Label for="passwordConfirm">New Password Confirm</Label>
          <Input type="text" name="confirmPassword" id="passwordConfirm" placeholder="New Password Confirm" value={formData.confirmPassword} onChange={changeFormData} />
        </FormGroup>
        <Button color="primary">Submit</Button>
      </Form>
    </Container>
  )
}

export default ResetPasswordContent